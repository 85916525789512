import React from 'react'
import Helmet from 'react-helmet'

const GuestsPageTemplate = (props) => {
  return (
    <div>
      <Helmet>
        <title>Nimber Inc</title>
        <meta name='description' content='Nimber Inc' />
      </Helmet>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/guests1.png' width='447' height='294'/>
              </div>
              <div className='column'>
                <img src='/img/guests1-txt.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-medium background-business-1'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/guests2-txt.png' width='447' height='294'/>
              </div>
              <div className='column'>
                <img src='/img/guests2.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/guests3.png' width='447' height='294'/>
              </div>
              <div className='column'>
                <img src='/img/guests3-txt.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hero is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-vcentered'>
              <div className='column'>
                <img src='/img/guests4-txt.png' width='447' height='294'/>
              </div>
              <div className='column'>
                <img src='/img/guests4.png' width='447' height='294'/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default GuestsPageTemplate
